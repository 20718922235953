import { Box } from '@mui/material'
import React from 'react'
import ForgotPasswordContainer from '../containers/ForgotPasswordContainer'

export default function ForgotPassword() {
  return (
    <Box>
      <ForgotPasswordContainer />
    </Box>
  )
}
